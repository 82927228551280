export const SERVER = process.env.REACT_APP_SERVER;

// export const AWS_CONFIG = {
//     Auth: {
//         identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL,
//         identityPoolRegion: process.env.REACT_APP_AWS_REGION,
//         region: process.env.REACT_APP_AWS_REGION,
//         userPoolId: process.env.REACT_APP_COGNITO_IDENTITY_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_COGNITO_IDENTITY_USER_POOL_CLIENT_ID,
//     },
//     Storage: {
//         bucket: process.env.REACT_APP_PUBLIC_UPLOADS_S3_BUCKET,
//         region: process.env.REACT_APP_AWS_REGION,
//     },
// };


// eslint-disable-next-line no-extend-native
Date.prototype.addYears = function (years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years)
    return date;
};


// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (hours) {
    var date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes) {
    var date = new Date(this.valueOf());
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};