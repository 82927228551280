import React from "react";
import { Row, Col, } from 'antd';

import Image from '../../../assets/images/home_pc.jpg';


const About = () => {

    return (
        <section id="about" className="scroll-section root-sec padd-tb-120" style={{ backgroundColor: "rgb(249 246 246)" }}>
            <div className="container">

                <Row className="" align="middle" gutter={[16, 16]} justify="space-around">

                    <Col sm={{ span: 24 }} lg={{ span: 8 }}>
                        <div className="">

                            <h3 className="about-subtitle">Over ons</h3>
                            <p>Vanuit de overtuiging dat de beste software wordt gebouwd door de combinatie van de eindgebruiker, informatieanalyse en development,
                                bouwt de Software Fabrique oplossingen die voor de klant werken.
                                Wij hebben reeds software producten op deze wijze ontwikkeld. Dit zijn SaaS oplossingen die direct zijn te gebruiken.
                                Staat de software die voor je werkt hier niet tussen? Dan bouwen we deze graag samen met jou.
                            </p>

                            {/* <a className="waves-effect waves-light btn-large brand-bg white-text"><i
                                className="mdi-content-archive left"></i> Download </a> */}
                        </div>
                    </Col>

                    <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="person-img wow fadeIn">
                            <img className="z-depth-1" src={Image} alt="" />
                        </div>
                    </Col>


                    {/* <Col span={8}>
                        <div className="person-info">
                            <h3 className="about-subtitle">Onze informatie</h3>
                            <h5><span>Name :</span> John Doe</h5>
                            <h5><span>Age :</span> 25 Years</h5>
                            <h5><span>Phone :</span> +0123456789</h5>
                            <h5><span>Email :</span> email@domain.com</h5>
                            <h5><span>Address :</span> Dhaka, Bangladesh</h5>
                        </div>
                    </Col> */}

                </Row>



            </div>
        </section>
    );
};

export default About;