import React from "react";
import { Image, } from 'antd';
import { HomeOutlined, ContactsOutlined, AppstoreOutlined, TeamOutlined, } from '@ant-design/icons';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/images/SoftwareFabrique_logo.png'

const HomeHeaderBar = () => {

    return (

        <Navbar bg="light" expand="md" className="primary-nav ">
            <Container>
                <Navbar.Brand href="/">
                    <Image
                        width={150}
                        preview={false}
                        src={Logo} alt="" />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse className="basic-navbar-nav inline-menu side-nav justify-content-center">
                    <Nav className="me-auto justify-content-center" style={{ width: "100%" }}>
                        <Nav.Item as="li">
                            <Nav.Link href="#about" className=""><HomeOutlined /> Over ons</Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                            <Nav.Link href="#saas" className=""><AppstoreOutlined /> SaaS</Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                            <Nav.Link href="#team" className=""><TeamOutlined /> Team</Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                            <Nav.Link href="#contact" className=""><ContactsOutlined /> Contact</Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>



    );
};

export default HomeHeaderBar;