import React from "react";
import { Row, Col, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { LinkedinOutlined, } from '@ant-design/icons';
import Image from '../../../assets/images/pand.jpg';

const Contact = () => {

    return (
        <section id="contact" className="scroll-section padd-tb-120" style={{ backgroundImage: "linear-gradient(0deg,#949494,#fff)" }}>

            <div className="container">
                <Row className="" align="bottom" gutter={[16, 16]} justify="space-around">

                    <Col sm={{ span: 24 }} lg={{ span: 11 }}>
                        <h3 className="about-subtitle">Ons HQ</h3>

                        <div className="person-img wow fadeIn">
                            <img className="z-depth-1" src={Image} alt="Bezoek BARTI midden in het hightech hart van Delft" />
                        </div>
                    </Col>

                    <Col sm={{ span: 24 }} lg={{ span: 11 }}>

                        <ul className="mb-4">
                            <li>Rotterdamseweg 183c</li>
                            <li>2629HD Delft</li>
                            {/*<li><FontAwesomeIcon className="job_icon" icon={faPhoneAlt} /> <FormattedMessage id="call-us" />: <a href="tel:+31882026100">088 - 2026 100</a></li>*/}
                            <li><LinkedinOutlined className="job_icon" /> <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/software-fabrique"><FormattedMessage id="follow-us" /></a></li>
                        </ul>

                        <div className="text-center">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4914.283453806059!2d4.378339001939091!3d51.98607039513324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b55ab8489c6f%3A0x3363edba951ed84d!2sSoftware%20Fabrique%20B.V.!5e0!3m2!1snl!2snl!4v1676468215647!5m2!1snl!2snl"
                                style={{ width: "100%" }}
                                // width="500"
                                height="450"
                                title="Google maps location Software Fabrique B.V."
                                // style="border:0;"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </Col>


                    {/* <Col span={8}>
                        <div className="person-info">
                            <h3 className="about-subtitle">Onze informatie</h3>
                            <h5><span>Name :</span> John Doe</h5>
                            <h5><span>Age :</span> 25 Years</h5>
                            <h5><span>Phone :</span> +0123456789</h5>
                            <h5><span>Email :</span> email@domain.com</h5>
                            <h5><span>Address :</span> Dhaka, Bangladesh</h5>
                        </div>
                    </Col> */}

                </Row>

            </div>
        </section>
    );
};

export default Contact;