import React from "react";
import { Row, Col, } from 'antd';

import TeamCard from './team.card';


const OurTeam = () => {

    return (
        <section id="team" className="scroll-section root-sec padd-tb-120 white">
            <div className="container">


                <Row className="">
                    <Col span={24} className="">
                        <h2 className="title">our team</h2>
                        <p className="regular-text">Software Fabrique is opgericht in 2023 door Bart & Joël.</p>
                    </Col>

                    <Col span={24} className="mt-4">

                        <Row className="" align="middle" gutter={[16, 16]} justify="space-around">

                            <TeamCard profile="bart" />
                            <TeamCard profile="joel" />
                            {/* <TeamCard /> */}

                        </Row>


                    </Col>

                </Row>
            </div>
        </section>
    );
};

export default OurTeam;