
import axios from 'axios';
import { SERVER as Server } from '../../config';

// import * as api from '../api';

export const ApplicationListPublic = () => {
    return (
        axios({
            url: `${Server}/public/application/list`,
            method: 'get',
            responseType: 'json',
            // ...config,
        })
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    );
};



// export const ApplicationListPublic = () => {
//     return new Promise((resolve, reject) => {
//         api
//             .get('/public/application/list')
//             .then(response => {
//                 resolve(response.data);
//             })
//             .catch(error => {
//                 console.error('error: ', error);
//                 reject(error);
//             })
//     });
// };
