import React from "react";
import { Col, Image, } from 'antd';
import { LinkedinFilled, } from '@ant-design/icons';
import ProfileJoel from '../../../assets/images/joel.png';
import ProfileBart from '../../../assets/images/bart.png';

const TeamCard = ({
    auth,
    history,
    profile,
    ...props
}) => {

    return (

        <Col className="single-card-box wow fadeInUpSmall animated">

            <div className="card">
                <div className="card-image waves-effect waves-block waves-light">
                    <div className="card-img-wrap">
                        {profile === "joel" ? (
                            <Image className="activator" preview={false} width={300} src={ProfileJoel} alt="" />
                        ) : (
                            <Image className="activator" preview={false} width={300} src={ProfileBart} alt="" />
                        )}
                    </div>
                </div>

                <div className="card-content">
                    <span className="card-title activator brand-text">
                        {profile === "joel" && (
                            <>
                                Joël van Amerongen
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/joel-van-amerongen/">
                                    <LinkedinFilled style={{ color: "#0a66c2" }} className="right" /></a>
                            </>)}
                        {profile === "bart" && (
                            <>
                                Bart de Paauw
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/bartdepaauw/">
                                    <LinkedinFilled style={{ color: "#0a66c2" }} className="right" /></a>
                            </>)}

                    </span>
                    <p>Co-founder</p>
                </div>

                {/* <div className="card-reveal" style={{ transform: "translateY(-100%)" }}>
                    <div className="rev-title-wrap">
                        <span className="card-title activator brand-text">Jonh Doe<i className="mdi-navigation-close right"></i></span>
                        <p>UX UI Designer</p>
                    </div>
                    <p className="rev-content">
                        Collaboratively initiate collaborative schemas for high-quality communities. Objectively
                        create sticky relationships via team building outsourcing.
                    </p>
                </div> */}

            </div>
        </Col>

    );
};


export default TeamCard;