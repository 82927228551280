import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import App from './App';

// import 'antd/dist/antd.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.css';
import './assets/styles/materialize.css';
import './assets/styles/color.css';



// Require Editor CSS files.
import 'react-quill/dist/quill.snow.css';

const container = document.getElementById('root');
const root = createRoot(container);
const defaultData = {
    borderRadius: 3,
    colorPrimary: '#1ebbd6',
    colorPrimaryHover: '#118093',
    hashed: false,
};

root.render(<React.StrictMode>
    <ConfigProvider
        theme={{
            token: {
                // colorPrimary: defaultData.colorPrimary,
                // colorPrimaryHover: defaultData.colorPrimaryHover,
                borderRadius: defaultData.borderRadius,
            },
            hashed: false,
        }}>
        <App />
    </ConfigProvider>
</React.StrictMode>);
// root.render(<App />);
