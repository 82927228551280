import React, { useState, useEffect, } from "react";
// import { FormattedMessage, } from "react-intl";
// import { Link, } from 'react-router-dom';
import { Row, Col, } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import { ApplicationListPublic } from '../../../services/application';

import ItemCard from './item.card';
import './pricing.style.css';

const ApplicationList = ({
    auth,
    history,
    ...props
}) => {

    const [applications, setApplications] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = () => {
        ApplicationListPublic().then(response => {
            // console.log('response: ', response.data);
            const data = response.data;
            if (data.status) {
                setApplications(data.data.applications, setDataLoading(false));
            } else {
                setDataLoading(false);
            }
        });
    };

    return (

        <section id="saas" className="scroll-section root-sec">
            <div className="sec-overlay">
                <div className="container padd-tb-120">

                    <Row className="">

                        <Col span={24}>
                            <h2 className="title">Onze software producten</h2>
                            <p className="regular-text">
                                Onze software is klaar voor gebruik.
                                Bij de software fabrique geen langdurige abonnementen maar juist maximale vrijheid, opzegbaar per maand.
                                Onze klanten houden ons scherp zodat de software voor hen blijft werken en altijd een meerwaarde blijft bieden.
                                Benieuwd, probeer het nu uit!
                            </p>
                        </Col>

                        <Col span={24} className="mt-4">


                            {dataLoading ? (
                                <Row className="" align="middle" justify="center">
                                    <div className="spinner-container">
                                        <LoadingOutlined />
                                    </div>
                                </Row>
                            ) : (
                                <Row className=""
                                    align="top"
                                    // gutter={[16, 16]}
                                    justify="space-around"
                                >

                                    {applications.map((item, index) => (
                                        <ItemCard
                                            key={index}
                                            data={item} />
                                    ))}

                                    {!dataLoading && applications.length === 0 && (
                                        <Col span={24}><span>Geen active applicaties</span></Col>
                                    )}
                                </Row>
                            )}

                        </Col>
                    </Row>

                </div>
            </div>
        </section>

    );
};


export default ApplicationList;