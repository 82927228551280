import React from "react";
// import { FormattedMessage, } from "react-intl";
// import { Link, } from 'react-router-dom';
import { Row, Button, Col, Image, } from 'antd';
import { GlobalOutlined, } from '@ant-design/icons';
// import { Link } from "react-router-dom";

// import Bike from '../../../assets/images/bike_child.jpg';
// import Logo from '../../../assets/images/SmartCloudControl_Logo_diap.png';

const ItemCard = ({
    history,
    data,
    ...props
}) => {

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (

        <Col
            sm={{ span: 20, }}
            md={{ span: 11, }}
            xl={{ span: 7, }}
            // xxl={{ span: 4 }}
            className="price-box-cointainer white">

            <div className="pricing-box">
                <div className="entry-image text-center">
                    <Image
                        // style={{ height: "300px", objectFit: "scale-down" }}
                        preview={false}
                        src={`${process.env.REACT_APP_AWS_DATASTORE}/${data.app_image_key}`} />
                </div>

                <div className="entry-inner">

                    <div className="upper">
                        <Row align="middle" justify="space-around">
                            {/* <Col span={7}><EuroCircleOutlined className="fa-3x" /></Col> */}
                            <Col span={24}><h3 className="h3">{data.app_name}</h3></Col>
                        </Row>
                    </div>

                    <div className="text mb-3">{renderHTMLContent(data.app_description)}</div>

                    {/* <div className="list">
    <h5 className="h5">Applicatie bevat:</h5>
    <ul>
        <li>Item A</li>
        <li>Item B</li>
        <li>Item C</li>
        <li>Item D</li>
    </ul>
</div> */}

                    <div className="end">
                        {/* <strong>€ 75,00</strong> <small>p/m</small> */}
                        <Button href="/"
                            className="float-end brand-bg white-text"
                        ><GlobalOutlined /> Lees meer</Button>
                    </div>

                </div>

            </div>

        </Col>

    );
};


export default ItemCard;