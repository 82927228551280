import React from "react";
// import { Row, Col, Button, Image, } from 'antd';
import { LinkedinOutlined, MailOutlined, } from '@ant-design/icons';

const PageFooter = () => {

    return (

        <footer id="footer" className="root-sec white root-sec footer-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="clearfix footer-inner">
                            <ul className="left social-icons">
                                {/* <li><a href="#!" className="tooltips tooltipped facebook" data-position="top" data-delay="50"
                                    data-tooltip="Facebook">
                                    <FacebookOutlined />
                                </a>
                                </li>
                                 */}
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/software-fabrique" className="">
                                        <LinkedinOutlined />
                                    </a>
                                </li>

                                {/* <li><a href="#!" className="tooltips tooltipped" data-position="top" data-delay="50"
                                    data-tooltip="Twitter">
                                    <TwitterOutlined />
                                </a>
                                </li>

                                <li><a href="#!" className="tooltips tooltipped" data-position="top" data-delay="50"
                                    data-tooltip="Google Plus">
                                    <PhoneOutlined />
                                </a>
                                </li> */}

                                <li><a href="mailto:info@softwarefabrique.nl" className="tooltips tooltipped" data-position="top" data-delay="50"
                                    data-tooltip="Dribbble">
                                    <MailOutlined /></a>
                                </li>

                            </ul>

                            <div className="right copyright">
                                <p>Software Fabrique B.V. &copy; {new Date().getFullYear()} All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default PageFooter;