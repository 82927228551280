import React from "react";
import { Layout, } from 'antd';

import { PublicLayout } from '../../components/layouts';
import HomePageHero from './views/hero';
import ApplicationList from './views/app.list';
import HomeHeaderBar from '../../components/header/header';
import PageFooter from '../../components/footer';
import OurTeam from './views/team';
import About from './views/about';
import Contact from './views/contact';

const { Content } = Layout;

const HomePage = ({
    props
}) => {

    return (
        <PublicLayout className="bg-gray-01">

            <HomeHeaderBar />
            <Content>

                <HomePageHero />
                <About />
                <ApplicationList />

                <OurTeam />
                <Contact />

            </Content>

            <PageFooter />
        </PublicLayout>
    );

};

export default HomePage;