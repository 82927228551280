import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Spinner from '../components/Spinner';

// import { AccountValidationPage } from '../cointainers/account.validation';

import HomePage from '../components/home';
// import ListPage from '../components/lists';
// import DetailPage from '../components/detail';

const NotFound = lazy(() => import('../components/Notfound'));
// const Login = lazy(() => import('./../cointainers/account.login'));
// const RequestPasswordPage = lazy(() => import('../cointainers/account.request.password'));
// const SignupPage = lazy(() => import('../cointainers/account.signup'));
// const ChangePasswordPage = lazy(() => import('../cointainers/account.change.password'));
// const AccountActivation = lazy(() => import('../cointainers/account.activate'));

// const UserDashboard = lazy(() => import('../cointainers/dashboard'));
// const PaymentPage = lazy(() => import('../cointainers/financial/payment.page'));


const Routers = ({ ...props }) => {
    // const { locale, setCurrentLanguage } = props;
    // const additional_props = { locale, setCurrentLanguage };
    const additional_props = {};

    return (
        <Suspense fallback={<Spinner />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" exact element={<HomePage {...additional_props} {...props} />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>

        </Suspense>
    );
};

export default Routers;
