import React from "react";
// import { FormattedMessage, } from "react-intl";
// import { Link, } from 'react-router-dom';
import { Row, Col, Image, } from 'antd';
import { ContactsOutlined, } from '@ant-design/icons';
import Logo from './../../../assets/images/SoftwareFabrique_logo.png';

const HomePageHero = ({
    auth,
    history,
    ...props
}) => {

    return (

        <section id="home" className="scroll-section root-sec grey lighten-5 home-wrap">
            <div className="sec-overlay">
                <div className="container">
                    <Row className="">
                        <Col span={24} className="">
                            <div className="home-inner">
                                <div className="center-align home-content">
                                    <Image src={Logo} preview={false} style={{ maxWidth: "400px" }} />
                                    {/* <h1 className="home-title">Hi, we are the Software<span>Fabrique</span></h1> */}
                                    <h2 className="home-subtitle">Software die voor je werkt</h2>
                                    <a href="#contact"
                                        className="hire-me-btn btn waves-effect waves-light btn-large brand-bg white-text regular-text">
                                        <ContactsOutlined /> Neem contact op
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>

    );
};


export default HomePageHero;